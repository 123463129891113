
export default {
  name: "YfnCoze",
  data() {
    return {
      visible: false,
      newMessage: "",
      messages: [
        {
          text: this.$translate("Hello! This is Yuna from YFN. How can I help you?"),
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          role: "YFN",
          id: "",
        },
      ],
      ciphertext: "",
      socket: null,
      heartbeatInterval: null,
      deviceId: "",
      userId: "",
      isLoading: false,
      isdestroy: false,
      isInit: true, //加载ws
      aiList:{},
      device:''
    };
  },
  created() {},
  mounted() {
    // this.initialSet();
  },
  beforeDestroy() {
    this.disconnectWebSocket();
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() === "" || this.isInit) return;
      this.isInit = true;
      const currentTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      this.messages.push({
        text: this.newMessage,
        time: currentTime,
        role: "user",
      });
      let ext_id = this.generateRandomNumber();
      this.aiList[ext_id]=[]
      this.$api.mixins.cozePush({
        //消息发送
        bot_id: "7470086965531181061",
        user_id: this.userId || this.deviceId,
        ext_id:ext_id,
        messages: [
          {
            role: "user",
            content_type: "text",
            content: this.newMessage,
          },
        ],
      });

      this.isLoading = true;
      this.newMessage = "";

      // 滚动到底部
      this.scrollTop()
    },
    connectWebSocket() {
      this.socket = new WebSocket(
        `wss://usmertrics.selleroa.com/v1/plugs/im/gateway/yfn/${this.device}/${
          this.userId || this.deviceId
        }/${encodeURIComponent(encodeURIComponent(this.ciphertext))}`
      ); // 替换为你的 WebSocket URL

      this.socket.onopen = () => {
        console.log("WebSocket connection established");
        this.isInit = false;
        this.startHeartbeat();
      };
      let content = "";
      this.socket.onmessage = (event) => {
        let data = JSON.parse(event.data);
        try {
          if (data.playload.startsWith("manual_reply:")) {
            //人工发送消息
            const jsonString = data.playload.substring(
              data.playload.indexOf("{")
            );
            const jsonObject = JSON.parse(jsonString);
            const currentTime = new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            const keys = Object.keys(this.aiList);
            const lastKey = keys[keys.length - 1]; //取出最新的id

            this.isInit = false;
            this.messages.push({
              text: jsonObject.message,
              time: currentTime,
              role: "YFN",
              id: lastKey,
            });
            // 滚动到底部
            this.scrollTop()
            this.isLoading = false;
            return
          }
          data = JSON.parse(data.playload)
          if (data.data.startsWith("data:")) {
            const jsonString = data.data.substring(
              data.data.indexOf("{")
            );
            const jsonObject = JSON.parse(jsonString);
            this.aiList[data.ext_id].push(jsonObject)
          }else if (data.data) {
            this.aiList[data.ext_id].push(data.data)
          }
        } catch (error) {}
        //AI发送消息
        if (data.data == "event:done") {
          const currentTime = new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });
          content = ""; //清空文字

          let isId = this.messages.findIndex((item)=>{
            return item.id == data.ext_id
          })
          if (isId !== -1) return false;

          this.isLoading = false;
          this.aiList[data.ext_id].forEach((res, index) => {
            if (res == "event:conversation.message.delta") {
              content = content + this.aiList[data.ext_id][index + 1]?.content;
            }
          });
          this.$api.mixins.cozeSave({
            user_id: this.userId,
            ai_response_content: content,
          });
          this.isInit = false;
          this.messages.push({
            text: content,
            time: currentTime,
            role: "YFN",
          });
          // 滚动到底部
          this.scrollTop()
        }
      };

      this.socket.onclose = () => {
        console.log("WebSocket connection closed");
        if (this.isdestroy) {
          this.stopHeartbeat();
        }
      };

      this.socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    },
    scrollTop(){
      // 滚动到底部
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messages;
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      });
    },
    startHeartbeat() {
      this.heartbeatInterval = setInterval(() => {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify({ type: "heartbeat" }));
          console.log("Heartbeat sent");
        } else {
          this.stopHeartbeat();
          this.initialSet();
        }
      }, 20000); // 每20秒发送一次心跳
    },
    stopHeartbeat() {
      clearInterval(this.heartbeatInterval);
    },
    disconnectWebSocket() {
      if (this.socket) {
        this.isdestroy = true;
        this.socket.close();
      }
    },
    // 随机数
    generateRandomNumber(length = 6) {
      const min = Math.pow(10, length - 1);
      const max = Math.pow(10, length) - 1;
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      return randomNum.toString().padStart(length, "0");
    },
    openChat(){
      this.initialSet()
      this.visible = true;
    },
    async initialSet() {
      this.isInit = true;
      this.deviceId =
        this.$cookies.get("deviceId") || this.$storage.get("deviceId") || "";
      this.userId =
        this.$cookies.get("userId") ||
        this.$storage.get("userId") ||
        this.deviceId ||
        "";
      let userInfo = this.$storage.get("user/info")
      this.device = this.generateRandomNumber();
      this.isdestroy = false;
      this.$api.mixins
        .createAuthInfo({
          deviceId: this.deviceId,
          deviceType: this.device,
          userId: this.userId,
          userName: userInfo?.nickName || "YFN",
          userPic: userInfo?.headImage||"https://cdn2.selleroa.com/yfn/stock/defaultHead@3x.png",
        })
        .then((res) => {
          this.ciphertext = res.ciphertext;
          this.connectWebSocket();
        });
    },
  },
};
